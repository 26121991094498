<template>
  <v-container
    ref="scrollcontainer"
    class="pa-0 grey lighten-2"
    style="height: 100%; margin-bottom: 4.75rem"
  >
    <v-container class="transparent" fluid>
      <v-layout row wrap>
        <v-container fluid class="pa-0 ma-0" grid-list-md>
          <v-layout row wrap style="height: auto">
            <v-flex
              v-for="(message, index) in messages"
              :key="index"
              xs10
              :offset-xs2="message.my"
            >
              <v-card style="border-radius: 5px">
                <v-card-text class="pb-0">{{ message.text }}</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <span class="text-caption">{{
                    message.time | formatDate("%H:%M")
                  }}</span>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-layout>
    </v-container>
    <v-footer class="py-1" style="height: auto" app>
      <v-layout class="pl-2" justify-center align-center>
        <v-text-field
          label="Write a message..."
          v-model="message"
          solo
          hide-details
          @keydown.enter.native="send"
        ></v-text-field>
        <v-btn ripple @click="send" color="primary" large icon>
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </v-layout>
    </v-footer>
  </v-container>
</template>

<script>
import filter from "lodash/filter";

export default {
  name: "Messenger",
  computed: {},
  data() {
    return {
      messages: [],
      message: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.messages.push({
        text: "How can I help you?",
        time: new Date(),
        my: false,
      });
    });
  },
  methods: {
    send() {
      if (this.message.length > 0) {
        this.messages.push({ text: this.message, time: new Date(), my: true });
        this.message = "";
      }
    },
  },
  watch: {
    messages(val) {
      if (
        filter(val, { my: true }).length > 0 &&
        filter(val, { auto: true, my: false }).length < 1
      ) {
        setTimeout(() => {
          this.messages.push({
            text:
              "Our Concierge will come to your room within the next 15 minutes. Thank you for your patience and have a great day.",
            time: new Date(),
            my: false,
            auto: true,
          });
        }, 1000);
      }

      //56 is the height of the app footer, so we need to account it in the if
      let el = document.scrollingElement || document.documentElement;
      if (el.scrollHeight > el.clientHeight - 56) {
        el.scrollTop = el.scrollHeight - el.clientHeight;
      }
    },
  },
};
</script>
